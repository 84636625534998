import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../../components/service-hero";
import OurServicesSection from "../../components/our-services-section";
import Reviews from "../../components/reviews";
import ImageSideContainer from "../../components/image-side-container";
import MotForm from "../../components/mot-form";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";

const WASPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
        reviewAuthor
        reviewDate
        reviewContent
      }
      schemaLogo: file(relativePath: { eq: "icon.png" }) {
        publicURL
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "wheel-alignment-and-suspension" }) {
        slug
        wheelAlignmentFields {
          wheelAlignmentBanner {
            wheelAlignmentBannerHeading
            wheelAlignmentBannerBackgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          wheelAlignmentContentSection {
            wheelAlignmentContentHeading
            wheelAlignmentContentLeft
            wheelAlignmentContentRight
          }
          wheelAlignmentForm {
            wheelAlignmentFormHeading
          }
          wheelAlignmentLeftImageRightContent {
            wheelAlignmentRightContentHeading
            wheelAlignmentRightContentText
            wheelAlignmentRightContentButton {
              title
              target
              url
            }
            wheelAlignmentLeftImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          wheelAlignmentReviews {
            wheelAlignmentReviewsHeading
          }
          wheelAlignmentServicesCards {
            wheelAlignmentServicesCardsHeading
            wheelAlignmentServicesCards {
              nodes {
                ... on WpService {
                  title
                  servicesPostFields {
                    serviceUrl {
                      target
                      title
                      url
                    }
                  }
                  featuredImage {
                    node {
                      altText
                      localFile {
                        publicURL
                        childImageSharp {
                          gatsbyImageData(
                            formats: [AUTO, WEBP]
                            quality: 100
                            transformOptions: {
                              cropFocus: CENTER
                              fit: CONTAIN
                            }
                            layout: CONSTRAINED
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        seoFields {
          title
          opengraphTitle
          opengraphDescription
          metaDescription
          localBusinessSchema
          image {
            node {
              altText
              localFile {
                childImageSharp {
                  original {
                    height
                    width
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFields, wheelAlignmentFields, slug },
  } = data;

  const {
    wheelAlignmentBanner,
    wheelAlignmentContentSection,
    wheelAlignmentForm,
    wheelAlignmentLeftImageRightContent,
    wheelAlignmentReviews,
    wheelAlignmentServicesCards,
  } = wheelAlignmentFields;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
    mpn: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    sku: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    description: `${seoFields?.metaDescription}`,
    logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
    name: "ECF Car Care",
    url: "https://www.ecfcarcare.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
      image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
      name: "ECF Car Care",
      url: "https://www.ecfcarcare.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "5",
      bestRating: "5",
      worstRating: "1",
    },

    review: {
      "@type": "Review",
      url: "https://www.ecfcarcare.co.uk/reviews",
      datePublished: `${data.reviews.reviewDate}`,
      reviewBody: `${data.reviews.reviewContent}`,
      author: {
        "@type": "Person",
        name: `${data.reviews.reviewAuthor}`,
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "ECF Car Care",
        sameAs: "https://www.ecfcarcare.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "What We Do",
        item: {
          url: `${siteUrl}/what-we-do`,
          id: `${siteUrl}/what-we-do`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Wheel Alignment And Suspension",
        item: {
          url: `${siteUrl}/what-we-do/wheel-alignment-and-suspension`,
          id: `${siteUrl}/what-we-do/wheel-alignment-and-suspension`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>

      <GatsbySeo
        title={seoFields?.title}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {wheelAlignmentBanner && (
          <section>
            <ServiceHero
              title={wheelAlignmentBanner.wheelAlignmentBannerHeading}
              backgroundImage={
                wheelAlignmentBanner.wheelAlignmentBannerBackgroundImage.node
                  ?.localFile.childImageSharp.gatsbyImageData
              }
              imgAlt={
                wheelAlignmentBanner.wheelAlignmentBannerBackgroundImage.node
                  ?.altText
              }
            />
          </section>
        )}
        {wheelAlignmentContentSection &&
          !checkPropertiesForNull(wheelAlignmentContentSection, [
            "wheelAlignmentContentHeading",
          ]) && (
            <section className="pt-5 pt-lg-8 position-relative">
              <Container>
                <Row>
                  <Col className="position-relative">
                    <h2 className="text-center pb-5">
                      {
                        wheelAlignmentContentSection.wheelAlignmentContentHeading
                      }
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <SafeHtmlParser
                      htmlContent={
                        wheelAlignmentContentSection?.wheelAlignmentContentLeft
                      }
                    />
                  </Col>
                  <Col lg={6}>
                    <SafeHtmlParser
                      htmlContent={
                        wheelAlignmentContentSection?.wheelAlignmentContentRight
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}

        {wheelAlignmentLeftImageRightContent &&
          !checkPropertiesForNull(wheelAlignmentLeftImageRightContent, [
            "wheelAlignmentRightContentHeading",
          ]) && (
            <div className="py-5 py-lg-8">
              <ImageSideContainer
                image={
                  wheelAlignmentLeftImageRightContent.wheelAlignmentLeftImage
                    .node?.localFile.childImageSharp.gatsbyImageData
                }
                imageAlt={
                  wheelAlignmentLeftImageRightContent.wheelAlignmentLeftImage
                    .node?.altText
                }
                title={
                  wheelAlignmentLeftImageRightContent.wheelAlignmentRightContentHeading
                }
                order="first"
                imagePadding=" pb-5 pb-lg-0    "
                text={
                  wheelAlignmentLeftImageRightContent.wheelAlignmentRightContentText
                }
                ctaButton={
                  wheelAlignmentLeftImageRightContent?.wheelAlignmentRightContentButton
                }
              />
            </div>
          )}

        <section>
          <Reviews
            title={wheelAlignmentReviews?.wheelAlignmentReviewsHeading}
          />
        </section>
        <section id="book" className="pb-5 pb-lg-0 pt-5 pt-lg-7 ">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6}>
                <h2 className="text-center pb-4">
                  {" "}
                  {wheelAlignmentForm?.wheelAlignmentFormHeading ||
                    "BOOK YOUR APPOINTMENT"}
                </h2>
                <div
                  style={{ background: "#F9F9F9", borderRadius: "18px" }}
                  className="p-md-5 p-4"
                >
                  <MotForm page="Wheel Alignment And Suspension" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {wheelAlignmentServicesCards &&
          !checkPropertiesForNull(wheelAlignmentServicesCards, [
            "wheelAlignmentReviewsHeading",
            "wheelAlignmentServicesCards",
          ]) && (
            <OurServicesSection
              title={wheelAlignmentServicesCards.wheelAlignmentReviewsHeading}
              services={
                wheelAlignmentServicesCards.wheelAlignmentServicesCards?.nodes
              }
            />
          )}
      </Layout>
    </>
  );
};

export default WASPage;
